@import '~@angular/material/prebuilt-themes/indigo-pink.css';
@import '../node_modules/@syncfusion/ej2-base/styles/material.css';
@import '../node_modules/@syncfusion/ej2-buttons/styles/material.css';
@import '../node_modules/@syncfusion/ej2-inputs/styles/material.css';
@import '../node_modules/@syncfusion/ej2-popups/styles/material.css';
@import '../node_modules/@syncfusion/ej2-lists/styles/material.css';
@import '../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css';
@import '../node_modules/@syncfusion/ej2-calendars/styles/material.css';
@import '../node_modules/@syncfusion/ej2-angular-calendars/styles/material.css';

/* @tailwind base;
@tailwind components;
@tailwind utilities; */
/* html,
body {
  height: 100%;
  background: #f5f5f5;
} */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Inter', sans-serif;
  /* background: radial-gradient(ellipse at center, #002447, #000); */
  color: #fff;
}
/* body {
  font-family: 'Arial', sans-serif,'Roboto',;
  background: radial-gradient(ellipse at center, #002447, #000);
  min-height: 100vh;
  margin: 0;

} */
/* body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
} */
col-md-10
{
  margin-top: 64px;
  padding: 0px;
}
.text-center {
  text-align: center;
}

.ml20 {
  margin-left: 20px !important;
}
.mr20 {
  margin-right: 20px !important;
}
.mt20 {
  margin-top: 20px !important;
}
.mb20 {
  margin-bottom: 20px !important;
}
.ml10 {
  margin-left: 10px !important;
}
.mr10 {
  margin-right: 10px !important;
}
.mt10 {
  margin-top: 10px !important;
}
.mb10 {
  margin-bottom: 10px !important;
}
mat-card mat-focus-indicator{
  background:black;
  color:white;
}
.background{
  background-color: black;
  color: white;
}
th, td {
  border: 1px solid #c1c1c1;
  padding: 8px;
  text-align: left;
  cursor: pointer;
  font-size: 12px;
  font-weight:500;
}
table {
  border-collapse: collapse;
  width: 100%;
}

th, td {
  border: 1px solid #c1c1c1;
  padding: 3px;
  text-align: left;
  text-align: center;
  cursor: pointer;
}

th {

  font-size: 13px;
  font-weight: 500;
  padding: 3px;
  text-align: center;
  cursor: pointer;

}

/* li{
  list-style: none;
  cursor: pointer;
} */
/* ul{
  text-decoration: none;
  display: flex;
  height: 20px;
    padding: 12px;
    cursor: pointer;
} */

a{
  text-decoration: none;
  font-size: 14px;
    font-weight: 500;
    color:black
}


.background{
  background-color: #161515;
  color: white;
}
.data-row {
  background-color: #161515;
  color: white;
}
.mat-icon{
  width: 22px;
}

.data-row td {
  padding: 8px;
  text-align: center;
}

.container2 {
  /* height: 689px; */
  overflow: auto;
  width: 100%;

}

.container2 thead tr {
  position: sticky;
  top: 0;
  background-color: #fff;
  z-index: 1;
}
.container3 {
  position: relative;
  width: 308px;
  text-align: center;
  margin-left: 428px;
  margin-bottom: 30px;
}


h4{
  font-weight:500;
  text-align: center;
  font-size: 30px;
  /* margin-top: 30px; */
  color: white;
  margin-bottom: -1px;

}
.content {
  /* margin-left: 200px; */
  padding: 20px;
}
.button-content{
  display: flex;
}

::-webkit-scrollbar {
  width: 8px; /* Width of the scrollbar */
  height: 8px; /* Height of the scrollbar, used in horizontal scrolling */
}

::-webkit-scrollbar-track {
  background: #1e1e1e; /* Background of the scrollbar track */
  border-radius: 10px; /* Rounds the corners of the scrollbar track */
}

::-webkit-scrollbar-thumb {
  background-color: #444; /* Color of the scrollbar thumb */
  border-radius: 10px; /* Rounds the scrollbar thumb */
  border: 2px solid #1e1e1e; /* Adds a border matching the track background */
}

::-webkit-scrollbar-thumb:hover {
  background-color: #ce3262; /* Change the thumb color when hovered */
}

::-webkit-scrollbar-corner {
  background: #1e1e1e; /* Background for the corner between horizontal and vertical scrollbars */
}

/* Firefox scrollbar customization */
* {
  scrollbar-width: thin; /* Makes the scrollbar thinner */
  scrollbar-color: #444 #1e1e1e; /* Thumb color and track color */
}
.full-page{
  height:100vh
}